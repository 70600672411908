import { Typography, Grid, Box, CardContent, Stack, Card } from '@mui/material';
import {
  BooleanInput,
  Button,
  Create,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRefresh,
  required,
  AutocompleteInput,
  TextField,
  ReferenceField,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { apiUrl } from '../constants';
import { useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@mui/material/styles';
import { values } from 'lodash';

interface BankInfo {
  bankId: string;
  name: string;
  logo: string;
  _id: string;
}

interface CardProviderInfo {
  cardProviderId: string;
  name: string;
  logo: string;
  _id: string;
}

interface RetrievedCardInfo {
  bankInfo: BankInfo;
  cardProviderInfo: CardProviderInfo;
  country: string;
  cardBankName: string;
  bin: string;
  cardTypeName: string;
  cardType: string;
  cardBankTypeName: string;
  bankId: string;
  cardBankType: string;
  cardProviderId: string;
  cardNetworkName: string;
  isoCode: string;
}

interface ImageField {
  rawFile?: File;
  src?: string;
  title?: string;
}

interface FormValues extends Record<string, any> {
  name: string;
  unlisted: boolean;
  last4Digit: string;
  bin: string;
  cardBankName: string;
  cardNetworkName: string;
  cardType: string;
  cardBankType: string;
  frontImageUrl?: ImageField;
  backImageUrl?: ImageField;
  userId: string;
}

const updateImageToFormData = ({ formdata, data, imageKey, dataKey }: any) => {
  if (data[dataKey]?.rawFile)
    return formdata.append(imageKey, data[dataKey]?.rawFile);
  else if (data[dataKey] === null)
    return formdata.append(imageKey + 'Remove', true);
  else return;
};

const CardCreate = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [retrievedCardInfo, setRetrievedCardInfo] = useState<
    RetrievedCardInfo | undefined
  >();
  const [isBinValidated, setIsBinValidated] = useState<boolean>(false);

  const handleValidate = async (binNumber: string) => {
    try {
      const response = await fetch(`${apiUrl}/cards/bin/${binNumber}`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('auth') || '',
        },
      });

      const data = await response.json();
      if (!data?.data) {
        notify('No data found for this BIN', { type: 'warning' });
        return;
      }

      const parsedData = JSON.parse(data?.data || '{}');
      const newCardInfo = {
        ...parsedData,
        bankInfo: JSON.parse(parsedData.bankInfo || '{}'),
        cardProviderInfo: JSON.parse(parsedData.cardProviderInfo || '{}'),
      };

      console.log('Retrieved card info:', newCardInfo);
      setRetrievedCardInfo(newCardInfo);
      setIsBinValidated(true);
    } catch (error: any) {
      notify('Failed to retrieve BIN details', { type: 'error' });
      console.error('BIN validation error:', error);
    }
  };

  const save = async (values: any) => {
    console.log('Submitting form with data:', values);

    if (
      !values.userId ||
      !values.name ||
      !values.last4Digit ||
      (!values.bin && !values.unlisted)
    ) {
      notify('Please fill in all required fields', { type: 'warning' });
      return;
    }

    if (!retrievedCardInfo && !values.unlisted) {
      notify('Please validate the BIN number first', { type: 'warning' });
      return;
    }

    const formdata = new FormData();

    // Add basic form fields
    formdata.append('name', values.name);
    formdata.append('unlisted', values.unlisted?.toString() || 'false');
    formdata.append('last4Digit', values.last4Digit);
    formdata.append('bin', values.bin);
    formdata.append('userId', values.userId);

    // Add card info fields

    if (!values.unlisted) {
      formdata.append('cardType', retrievedCardInfo?.cardType || '');
      formdata.append('cardBankType', retrievedCardInfo?.cardBankType || '');
      formdata.append(
        'cardProviderId',
        retrievedCardInfo?.cardProviderId || ''
      );
      formdata.append('isoCode', retrievedCardInfo?.isoCode || '');
      formdata.append('country', retrievedCardInfo?.country || '');
      formdata.append('bankId', retrievedCardInfo?.bankInfo?._id || '');
    }

    formdata.append('cardProviderId', values.cardProviderId || '');
    formdata.append('bankId', values.bankId || '');

    // Handle image uploads
    updateImageToFormData({
      formdata,
      data: values,
      imageKey: 'cardFrontImage',
      dataKey: 'frontImageUrl',
    });
    updateImageToFormData({
      formdata,
      data: values,
      imageKey: 'cardBackImage',
      dataKey: 'backImageUrl',
    });

    try {
      const response = await fetch(`${apiUrl}/cards`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('auth') || '',
        },
        body: formdata,
      });

      const result = await response.json();

      if (result.code === 'MSG_0037') {
        notify('Card was created successfully', { type: 'success' });
        refresh();
      } else {
        notify(result.message || 'Something went wrong', { type: 'error' });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      notify('Failed to create card', { type: 'error' });
    }
  };

  return (
    <Create redirect='false'>
      <SimpleForm onSubmit={save} mode='onBlur'>
        <FormContent
          retrievedCardInfo={retrievedCardInfo}
          handleValidate={handleValidate}
          isBinValidated={isBinValidated}
          setIsBinValidated={setIsBinValidated}
        />
      </SimpleForm>
    </Create>
  );
};

interface FormContentProps {
  retrievedCardInfo?: RetrievedCardInfo;
  handleValidate: (bin: string) => Promise<void>;
  isBinValidated: boolean;
  setIsBinValidated: (isBinValidated: boolean) => void;
}

const FormContent = ({
  retrievedCardInfo,
  handleValidate,
  isBinValidated,
  setIsBinValidated,
}: FormContentProps) => {
  const form = useFormContext();
  const notify = useNotify();
  const theme = useTheme();

  useEffect(() => {
    if (retrievedCardInfo && !form.watch('unlisted')) {
      form.setValue('cardBankName', retrievedCardInfo.bankInfo?._id || '');
      form.setValue(
        'cardNetworkName',
        retrievedCardInfo.cardProviderInfo?._id || ''
      );
      form.setValue('cardType', retrievedCardInfo.cardType || '');
      form.setValue('cardBankType', retrievedCardInfo.cardBankType || '');
    }
  }, [retrievedCardInfo, form]);

  useEffect(() => {
    form.setValue('bankId', form.getValues('bankId'));
    form.setValue('cardProviderId', form.getValues('cardProviderId'));
  }, [form.watch('bankId')]);

  return (
    <Card sx={{ p: 3, mb: 2 }}>
      <CardContent>
        <Typography
          variant='h6'
          gutterBottom
          sx={{
            color: theme.palette.primary.main,
            mb: 3,
            fontWeight: 'medium',
          }}
        >
          Card Details
        </Typography>

        <Grid container spacing={3}>
          {/* Customer Selection */}
          <Grid item xs={12} sm={6}>
            <Box sx={{ maxWidth: '400px' }}>
              <ReferenceInput
                source='userId'
                reference='customers'
                label='Customer'
              >
                <AutocompleteInput
                  validate={[required('Please select a customer')]}
                  optionText={(record) => <UserOptionDisplay record={record} />}
                  inputText={(record) =>
                    record?.personalDetails
                      ? `${record.personalDetails.firstName || ''} ${
                          record.personalDetails.lastName || ''
                        }`
                      : ''
                  }
                  optionValue='userId'
                  fullWidth
                  sx={{ mb: 2 }}
                  createText='Select a customer'
                />
              </ReferenceInput>
            </Box>
          </Grid>

          {/* Card Holder Name */}
          <Grid item xs={12} sm={6}>
            <Box sx={{ maxWidth: '400px' }}>
              <TextInput
                label='Card Holder Name'
                source='name'
                validate={[required('Please enter card holder name')]}
                fullWidth
              />
            </Box>
          </Grid>

          {/* Last 4 Digits */}
          <Grid item xs={12} sm={6}>
            <Box sx={{ maxWidth: '400px' }}>
              <TextInput
                inputProps={{ maxLength: 4 }}
                label='Last 4 Digits'
                source='last4Digit'
                validate={[required('Please enter last 4 digits')]}
                fullWidth
                type='number'
              />
            </Box>
          </Grid>

          {/* Unlisted Toggle */}
          <Grid item xs={12} sm={6}>
            <Stack>
              <BooleanInput
                label='Active'
                source='unlisted'
                defaultValue={true}
                parse={(v) => !v}
                format={(v) => !v}
              />
            </Stack>
          </Grid>

          {/* BIN Input with Validate Button */}
          {!form.watch('unlisted') && (
            <Grid item xs={12} sm={6}>
              <Box sx={{ maxWidth: '400px' }}>
                <Stack direction='row' spacing={2} alignItems='flex-start'>
                  <TextInput
                    inputProps={{ maxLength: 6 }}
                    label='BIN'
                    source='bin'
                    // validate only if unlisted is false
                    validate={
                      form.watch('unlisted')
                        ? []
                        : [required('Please enter BIN')]
                    }
                    fullWidth
                    type='number'
                  />

                  <Button
                    onClick={() => {
                      const binInput = document.querySelector(
                        'input[name="bin"]'
                      ) as HTMLInputElement;
                      const binValue = binInput?.value;
                      if (!binValue) {
                        notify('Please enter a BIN number', {
                          type: 'warning',
                        });
                        return;
                      }
                      if (!/^\d{6}$/.test(binValue)) {
                        notify('BIN must be exactly 6 digits', {
                          type: 'warning',
                        });
                        return;
                      }
                      handleValidate(binValue);
                    }}
                    sx={{
                      mt: 1,
                      width: '100px',
                      backgroundColor: 'primary.main',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                    label='Validate'
                  />
                </Stack>
              </Box>
            </Grid>
          )}

          {form.watch('unlisted') && !isBinValidated && (
            <>
              <Grid item xs={12} sm={6}>
                <ReferenceInput
                  label='Card Bank Name'
                  source='bankId'
                  reference='banks'
                  fullWidth={true}
                >
                  <AutocompleteInput
                    optionText={(choice: BankInfo) => (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <img
                          src={choice.logo}
                          alt={choice.name}
                          style={{ width: 30 }}
                        />
                        <Typography>{choice.name}</Typography>
                      </Stack>
                    )}
                    inputText={(choice: BankInfo) => choice.name}
                    optionValue='dbId'
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReferenceInput
                  label='Card Network Name'
                  source='cardProviderId'
                  reference='cardProviders'
                  fullWidth={true}
                >
                  <AutocompleteInput
                    optionText={(choice: CardProviderInfo) => (
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <img
                          src={choice.logo}
                          alt={choice.name}
                          style={{ width: 30 }}
                        />
                        <Typography>{choice.name}</Typography>
                      </Stack>
                    )}
                    inputText={(choice: CardProviderInfo) => choice.name}
                    optionValue='dbId'
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
            </>
          )}

          {/* Bank Name, Card Network name, Card Type, Card Bank Type -- Retrieved from BIN */}
          {isBinValidated && (
            <>
              {/* Bank Info Display */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    maxWidth: '400px',
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary.main'
                    gutterBottom
                  >
                    Bank Name
                  </Typography>
                  <Typography variant='body1'>
                    {retrievedCardInfo?.bankInfo?.name || 'Not Available'}
                  </Typography>
                </Box>
              </Grid>

              {/* Card Network Name */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    maxWidth: '400px',
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary.main'
                    gutterBottom
                  >
                    Network Name
                  </Typography>
                  <Typography variant='body1'>
                    {retrievedCardInfo?.cardProviderInfo?.name ||
                      'Not Available'}
                  </Typography>
                </Box>
              </Grid>

              {/* Card Type */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    maxWidth: '400px',
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary.main'
                    gutterBottom
                  >
                    Card Type
                  </Typography>
                  <ReferenceField
                    source='cardType'
                    reference='cardTypes'
                    link={false}
                    record={{ cardType: retrievedCardInfo?.cardType }}
                  >
                    <TextField source='name' />
                  </ReferenceField>
                </Box>
              </Grid>

              {/* Card Bank Type */}
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    maxWidth: '400px',
                    p: 2,
                    borderRadius: 1,
                    backgroundColor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    color='primary.main'
                    gutterBottom
                  >
                    Card Bank Type
                  </Typography>
                  <ReferenceField
                    source='cardBankType'
                    reference='cardBankTypes'
                    link={false}
                    record={{ cardBankType: retrievedCardInfo?.cardBankType }}
                  >
                    <TextField source='name' />
                  </ReferenceField>
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        {/* Card Images */}
        <Box sx={{ mt: 4 }}>
          <Typography
            variant='h6'
            gutterBottom
            sx={{ color: 'primary.main', mb: 3, fontWeight: 'medium' }}
          >
            Card Images
          </Typography>
          <Grid container spacing={4}>
            <CardImage keyName={'frontImageUrl'} label='Front Image' />
            <CardImage keyName={'backImageUrl'} label='Back Image' />
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

const UserOptionDisplay = ({ record }: any) => {
  if (!record) return null;

  const name =
    record.personalDetails?.firstName +
      ' ' +
      record.personalDetails?.lastName || 'N/A';
  const mobile = record.personalDetails?.mobile || 'N/A';
  const email = record.personalDetails?.email || 'N/A';

  return (
    <Box sx={{ p: 0.5 }}>
      <Stack spacing={0.5}>
        <Typography variant='body1'>{name}</Typography>
        <Typography variant='body2' color='text.secondary'>
          {mobile} | {email}
        </Typography>
      </Stack>
    </Box>
  );
};

const CardImage = ({ keyName, label }: any) => {
  const imageUrl = useWatch<{ [keyName: string]: string }>({
    name: keyName,
  }) as any;

  return (
    <Grid item xs={12} sm={6}>
      <Box
        sx={{
          '.RaImageInput-preview': {
            borderRadius: 1,
            overflow: 'hidden',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
          img: {
            width: '100%',
            height: 200,
            objectFit: 'cover',
            borderRadius: 1,
          },
        }}
      >
        <ImageInput source={keyName} label={label} accept='image/*'>
          <img src={imageUrl?.src} alt={label} />
        </ImageInput>
      </Box>
    </Grid>
  );
};

export default CardCreate;
