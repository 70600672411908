import {
  BooleanField,
  Datagrid,
  FunctionField,
  ImageField,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin';

const cardProviderFilters = [
  <TextInput source='q' label='Search' alwaysOn />,
];

const CardProviderList = () => {
  return (
    <List exporter={false} filters={cardProviderFilters}>
      <Datagrid bulkActionButtons={false} rowClick='edit'>
        <TextField label='ID' source='id' />
        <TextField label='Name' source='name' />
        <ImageField
          label='Logo'
          source='logo'
          sx={{
            height: '30px',
            img: { height: '20px !important', width: 'auto !important' },
          }}
        />
      </Datagrid>
    </List>
  );
};

export default CardProviderList;
